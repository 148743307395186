import {
  RouteLocationNormalizedLoaded,
  RouteLocationResolved,
  RouteMap,
} from "vue-router";
import { NavMenuItem } from "ecom-types";

export const useBreadcrumbs = () => {
  const generalStore = useGeneralStore();
  const router = useRouter();

  function getRouteFullPath(
    r: RouteLocationNormalizedLoaded | RouteLocationResolved<keyof RouteMap>,
  ) {
    return router.resolve({
      path: r.path,
      hash: r.hash,
    }).fullPath;
  }

  function findBreadcrumb(
    items: TreeItem<NavMenuItem>[],
    route: RouteLocationNormalizedLoaded,
  ): BreadcrumbsArray {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      const url = getRouteFullPath(route);

      const clearUrl = item.url.startsWith("/")
        ? getRouteFullPath(router.resolve(item.url))
        : item.url;

      if (clearUrl && url === clearUrl && !item.is_title) {
        return [
          {
            id: item.id,
            text: item?.currentTranslation?.title || "",
            href: item.url,
            disabled: true,
          },
        ];
      }

      if (item.children && item.children.length > 0) {
        const breadcrumb = findBreadcrumb(item.children, route);

        if (breadcrumb.length > 0) {
          return item.is_title === false
            ? [
                {
                  id: item.id,
                  text: item?.currentTranslation?.title || "",
                  href: item.url,
                  disabled: false,
                },
                ...breadcrumb,
              ]
            : breadcrumb;
        }
      }
    }
    return [];
  }
  function setBreadcrumbs(items: BreadcrumbsArray): void {
    generalStore.breadcrumbs = items;
  }
  return {
    setBreadcrumbs,
    findBreadcrumb,
  };
};
